.game-container{
    height: 100%;
    width: 100%;
    
    background-color: #DEDDDE;
    overflow-y: hidden;
}

.o-container{
    max-width: 100%;
    height: 85%;
}

.credits-kaleido{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    bottom: 10px;
    right: 20px;
    pointer-events: none;
}

@media only screen and (max-width: 992px) {
    .game-container {
        overflow-y: auto;
    }

    .credits-kaleido{
        position: static;
    }

    .o-container{
        height: auto;
    }
  }

