#game-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
}

.context-container{
    width: 80%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#text-context{
    width: 40%;
    text-align: center;
}

.avatar-container{
    width: 28%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.objetivo-container{
    width: 28%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.8rem;
    border: #457b9d 2px solid;
    text-align: justify;
}

.objetivo-container h3{
    color: #457b9d;
    font-weight: bolder;
    margin-bottom: 2rem;
}

.objetivo-container p{
    text-align: justify;
    font-size: 1.1rem;
    line-height: 1.3rem;
}

.game-buttons-container{
    width: 100%;
    padding: 0 1rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.finish-text{
    padding: 4em 30%;
    text-align: center;
}

.finish-text h1{
    font-size: 1em;
}

@media only screen and (max-width: 992px) {
    .context-container{
        height: auto;
        flex-direction: column;
    }

    .avatar-container{
        width: 100%;
        /* height: 100%*/
    }

    #text-context{
        width: 100%;
        margin: 1em 0;
    }

    .objetivo-container{
        width: 100%;
    }

    .game-buttons-container{

        position: relative;
        order: -1;
    }

    .finish-text{
        padding: 4em 10%;
    }

    .button-time{
        border: none;
        background: none;
    }

    .image-time{
        height: 1.7em;
    }
}



