.inventory-item-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: .5rem 0;
}

.item-tittle{

}

.checkbox {
    display: inline-block;
    cursor: pointer;
    font-size: 13px; line-height:18px;
}

input[type=checkbox] {
    display:none;   
}

.checkbox:before {
    content: "";
    display: inline-block;
    width: 3.5rem;
    height: 3.5rem;
    vertical-align: middle;
    /*background: url("https://cdn1.iconfinder.com/data/icons/windows8_icons_iconpharm/26/checked_checkbox.png");*/
    background-size: contain;
    
    color: #f3f3f3;
    text-align: center;
}

input[type=checkbox]:checked + .checkbox:before {
    /*background: url("https://cdn1.iconfinder.com/data/icons/windows8_icons_iconpharm/26/checked_checkbox.png");*/
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    font-size: 15px;
    background-size: contain;
    border-radius: .5rem;
    background-color: rgba(248,183,51,.5);
    padding: 2rem;
}

.checkbox-disabled{
    filter: grayscale(100%) !important;
}
