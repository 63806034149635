.avatar-container-inner{
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}



.parent {
  width:100%;
  height: 80%;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image1 {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.image2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;

}
a {
    color: gray;
    font-size: 12px;
    text-decoration: none;
    padding: 1em;
}

@media only screen and (max-width: 992px) {
  .parent {
    height: 15em;
  }

  .avatar-container-inner{
    margin-top: 1em;
  }
}