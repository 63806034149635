.camera-container{
    width: 40%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#camera{
    height: 100%;
    width: 100%;
    border-radius: .5rem;
}

@media only screen and (max-width: 992px) {
    .camera-container{
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}