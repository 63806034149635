.introduction-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.introduction-video{
    max-width: 90%;
    min-width: 90%;
    max-height: 90%;
    min-height: 30%;
    margin: 1rem;
}