#principal-section{
    width: 99%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

/* width */
#principal-section::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
#principal-section::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
   
/* Handle */
#principal-section::-webkit-scrollbar-thumb {
    background: #457b9d; 
    border-radius: 10px;
}
  
/* Handle on hover */
    #principal-section::-webkit-scrollbar-thumb:hover {
    background: #457b9d; 
}
