.inventory-container{
    background-color: rgba(194, 194, 194, 0.5);
    display: flex;
    justify-content: center;
    flex-flow: wrap;
}

.bg-modal{
    /*background-color: rgba(133, 133, 133, 0.562);*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-footer{
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
}

