.form-inline{
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
}

.logo-header{
    object-fit: contain;
}



@media (orientation: portrait) {
    .logo-header{
        max-width: 40%;
    }
  }
